export class CouponConstants {
  static issuerType = {
    store: { value: 1, label: '店舗発行' },
    ownOffice: { value: 2, label: '事務局(個別)' },
    allOffice: { value: 3, label: '事務局(全体)' },
  };
  static validFlagType = {
    public: { value: 1, label: '公開' },
    private: { value: 2, label: '非公開' },
  };
  static expiredType = {
    publicing: { value: 1, label: '公開中' },
    beforePublic: { value: 2, label: '公開前' },
    invalid: { value: 3, label: '有効期限切れ' },
    pause: { value: 4, label: '一時停止中' },
    stop: { value: 5, label: '休業中' },
    outOfUsed: { value: 6, label: '利用回数達成' },
  };
  static distributionType = {
    distributed: { value: 1, label: '配布済' },
    notDistribute: { value: 2, label: '未配布' },
  };
}
